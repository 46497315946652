*, :after, :before {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

header {
  width: 100%;
  pointer-events: none;
  padding: 3vw;
  position: relative;
}

.btn-menu, .btn-close {
  z-index: 13;
  height: 24px;
  width: 24px;
  cursor: pointer;
  position: absolute;
  top: 3vw;
  right: 3vw;
}

.btn-menu img, .btn-close img {
  width: 100%;
}

.btn-menu img:hover, .btn-close img:hover {
  opacity: .8;
}

.btn-menu {
  pointer-events: visible;
}

.btn-close {
  z-index: 100;
}

.revealer-animation {
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.block-revealer {
  display: none;
}

.block-revealer.menu-open {
  display: block;
}

.revealer-content {
  min-height: 100%;
  opacity: 0;
  position: relative;
  overflow: auto;
}

.menu {
  height: 100%;
  z-index: 100;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
}

.menu-inner {
  width: 100%;
  min-height: 100%;
  background: #222;
  margin: 0;
  padding: 20vh 3em 5vh;
  list-style-type: none;
  position: absolute;
  top: 0;
  left: 0;
}

.menu-item {
  padding: 15px;
  transition: transform 0s .4s;
  transform: translate3d(-20em, 0, 0);
}

.revealer-content.active .menu-item {
  opacity: 1;
  transition: opacity .4s .135s, transform .4s cubic-bezier(.645, .045, .355, 1) .135s;
  transform: translate3d(0, 0, 0);
}

.menu-open .menu-item:nth-child(2) {
  transition-delay: .165s;
}

.menu-open .menu-item:nth-child(3) {
  transition-delay: .195s;
}

.menu-open .menu-item:nth-child(4) {
  transition-delay: .225s;
}

.menu .menu-link {
  color: #40284c;
  font-size: 3.5vw;
  font-weight: bold;
  text-decoration: none;
}

.menu .menu-link:hover, .menu .menu-link:focus {
  color: #000;
  outline: none;
  text-decoration: line-through;
}

.revealer-content {
  color: #fff;
}

.revealer-content section {
  margin-bottom: 50px;
}

.revealer-content h3 {
  color: #fff;
  padding-bottom: 32px;
  font-size: 24px;
  line-height: 35px;
}

.revealer-content .about .about-features {
  padding-top: 50px;
}

.revealer-content .about .about-feat {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 700;
}

.revealer-content .about .feature-icon img {
  height: 32px;
}

.revealer-content .gallery .project-gal-wrap {
  padding-top: 20px;
}

.revealer-content .gallery figcaption {
  text-align: center;
  text-transform: capitalize;
  color: #888;
  padding: 10px 0 30px;
  font-size: 12px;
}

.revealer-content .team .team-name {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 13px;
}

.revealer-content .team .team-job {
  color: #888;
  margin-bottom: 17px;
  font-size: 12px;
}

.revealer-content .maps {
  height: 420px;
  margin: 0 -3em -20vh;
  position: relative;
  overflow: hidden;
}

.revealer-content .maps #map_canvas {
  height: 100%;
  width: 100%;
  z-index: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.03d6d822.css.map */
