/*
Theme Name: HUGE
Description: Creative Coming Soon Template
Author: SquirrelLabs
Author URI: https://themeforest.net/user/squirrellabs/portfolio?ref=SquirrelLab
Version: 1.0
License: https://themeforest.net/licenses/standard

---

/*******************************
  Table of Contents

  1.0 Reveal
  2.0 Reveal Content
*******************************/

/******************************/
/*******   1.0 Reveal   *******/
/******************************/
*,
*::after,
*::before {
	box-sizing: border-box;
}

html, body {
	width: 100%;
	overflow-x: hidden;
}

header {
	position: relative;
	width: 100%;
	padding: 3vw;
	pointer-events: none;
}

.btn-menu, .btn-close {
	position: absolute;
	z-index: 13;
	top: 3vw;
	right: 3vw;
	height: 24px;
	width: 24px;
	cursor: pointer;
}
.btn-menu img,
.btn-close img {
	width: 100%;
}
.btn-menu img:hover,
.btn-close img:hover {
	opacity: .8
}

.btn-menu {
	pointer-events: visible;
}

.btn-close {
	z-index: 100;
}

.revealer-animation {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	pointer-events: none;
	opacity: 0;
}
.block-revealer {
	display: none;
}
.block-revealer.menu-open {
	display: block;
}

.revealer-content {
	min-height: 100%;
	position: relative;
	opacity: 0;
	overflow: auto;
}

.menu {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 100;
	padding: 0;
}

.menu-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	list-style-type: none;
	padding: 20vh 3em 5vh 3em;
	/*padding: 20vh 3em;*/
	margin: 0;
	background: #222;
}

.menu-item {
	padding: 15px;
	-webkit-transform: translate3d(-20em,0,0);
    	-ms-transform: translate3d(-20em,0,0);
			transform: translate3d(-20em,0,0);
	-webkit-transition: transform 0s 0.4s;
    	-ms-transition: transform 0s 0.4s;
			transition: transform 0s 0.4s;
}

.revealer-content.active .menu-item {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
    	-ms-transform: translate3d(0,0,0);
			transform: translate3d(0,0,0);
	-webkit-transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
    	-ms-transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
			transition: opacity 0.4s 0.135s, transform 0.4s 0.135s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.menu-open .menu-item:nth-child(2) {
	-webkit-transition-delay: 0.165s;
    	-ms-transition-delay: 0.165s;
			transition-delay: 0.165s;
}

.menu-open .menu-item:nth-child(3) {
	-webkit-transition-delay: 0.195s;
    	-ms-transition-delay: 0.195s;
			transition-delay: 0.195s;
}

.menu-open .menu-item:nth-child(4) {
	-webkit-transition-delay: 0.225s;
    	-ms-transition-delay: 0.225s;
			transition-delay: 0.225s;
}

.menu .menu-link {
	font-size: 3.5vw;
	color: #40284c;
	font-weight: bold;
	text-decoration: none;
}

.menu .menu-link:hover,
.menu .menu-link:focus {
	outline: none;
	color: #000;
	text-decoration: line-through;
}

/******************************/
/***   2.0 Reveal Content   ***/
/******************************/
.revealer-content {
	color: #fff
}
.revealer-content section {
	margin-bottom: 50px;
}
.revealer-content h3 {
    font-size: 24px;
    line-height: 35px;
    padding-bottom: 32px;
    color: #fff;
}

/*** About ***/
.revealer-content .about .about-features {
	padding-top: 50px;
}
.revealer-content .about .about-feat {
	font-weight: 700;
	font-size: 14px;
	margin-top: 30px;
}
.revealer-content .about .feature-icon img {
    height: 32px;
}

/*** Gallery ***/
.revealer-content .gallery .project-gal-wrap {
	padding-top: 20px;
}

.revealer-content .gallery figcaption {
	font-size:12px;
	text-align: center;
	text-transform:capitalize;
	color:#888;
	padding: 10px 0 30px 0;
}

/*** Team ***/
.revealer-content .team .team-name {
    font-size: 13px;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 3px;
    margin-top: 20px;
}
.revealer-content .team .team-job {
    font-size: 12px;
    color: #888;
    margin-bottom: 17px;
}

/*** Maps ***/
.revealer-content .maps {
    overflow: hidden;
    position: relative;
    height: 420px;
    margin: 0 -3em -20vh; 
}
.revealer-content .maps #map_canvas {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
